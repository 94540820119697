<template>
    <div>
        <el-date-picker
            v-model="dates"
            type="daterange"
            range-separator="—"
            start-placeholder="Дата заезда"
            end-placeholder="Дата выезда"
            :picker-options="dateOptions"
            @change="setDates(dates)"
            @changerange="consoleLog(1)"
            @input="checkMinDays($event)"
        >
        </el-date-picker>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { formatDateToUTC } from '../api'
export default {
    data() {
        return {
            dates: [this.$store.state.startDate, this.$store.state.endDate],
            dateOptions: {
                firstDayOfWeek: 1,
                disabledDate(time) {
                    return time.getTime() < Date.now() - 24 * 3600 * 1000;
                },
            },
        };
    },
    props: {
        nightInfo: Function
    },
    methods: {
        ...mapActions([
            "SET_START_DATE",
            "SET_END_DATE",
            "SET_END_DATE_PREV",
            "SET_DAYS",
            "SET_NIGHTS_INFO"]),
        setDates(dates) {
            const dateDiff = Math.abs(
                Date.parse(dates[1].toDateString()) - Date.parse(dates[0].toDateString())
            );
            const startDate = new Date(dates[0])
            const endDate = new Date(dates[1])
            const endDatePrev = new Date(dates[1] - 1)
            const days = Math.ceil(dateDiff / (1000 * 3600 * 24));
            this.SET_DAYS(days);
            this.SET_START_DATE(formatDateToUTC(startDate));
            this.SET_END_DATE(formatDateToUTC(endDate));
            this.SET_END_DATE_PREV(formatDateToUTC(endDatePrev))
            this.SET_NIGHTS_INFO(this.nightInfo())
            this.$emit("isFill");
            this.$emit("datesChange", String(dates));
        },
        showMessage(msg, msgType) {
            this.$message({
                message: msg,
                type: msgType,
                duration: 4000,
            });
        },
        checkMinDays(event) {
            if (Math.ceil((event[1] - event[0])/ (1000 * 3600 * 24)) < 2) {
                let endDate = new Date(event[0])
                endDate.setDate(endDate.getDate() + 2)
                this.dates[1] = endDate
                this.showMessage('Минимальное кол-во ночей: 2', "success");
            }
            
        }
    },
};
</script>
